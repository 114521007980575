.tooltip-dark {
  background-color: #005662;
  color: #ffffff;
  padding: 5px;
  border-radius: 5px;
  font-size: 13px;
  text-align: center;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  white-space: nowrap;

  &:after {
    border-top-color: #005662;
    border-top-style: solid;
    border-top-width: 6px;
  }

  &:after {
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    bottom: -6px;
    left: 50%;
    margin-left: -8px;
  }
  &:before,
  &:after {
    content: '';
    width: 0;
    height: 0;
    position: absolute;
  }

  &.place-right {
    &:after {
      border-right-color: #005662;
      border-right-style: solid;
      border-right-width: 6px;
    }

    &:after {
      border-top: 8px solid transparent;
      border-bottom: 8px solid transparent;
      left: -6px;
      top: 50%;
      margin-top: -8px;
    }
  }
}

.tooltip-light {
  background-color: #f2f2f2;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  white-space: nowrap;
}
